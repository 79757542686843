import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addData } from '@/actions/auth';
import Head from 'next/head';

import dynamic from 'next/dynamic';
const LandingLayout = dynamic(() => import('@/components/landing/home/LandingLayout'), { ssr: false });
const MainBanner = dynamic(() => import('@/components/landing/home/MainBanner'), { ssr: false });

function LoginPage() {
  const dispatch = useDispatch();

  const isLogin = () => {
    const token = window.localStorage.getItem('token');
    if (token) {
      window.location.pathname = '/';
    } else {
      dispatch(
        addData({
          key: 'isLoggedIn',
          value: false,
        })
      );
    }
  };

  useEffect(() => {
    isLogin();
  }, []);

  return (
    <>
      <Head>
        <link rel="canonical" href={`${process.env.NEXT_PUBLIC_SITE_URL}login`} />
      </Head>
      <LandingLayout>
        <div className="ptb-100 login-content">
          <MainBanner backgroundColorWhite mission={''} isLoginPage={true} />
        </div>
      </LandingLayout>
    </>
  );
}

export default LoginPage;
